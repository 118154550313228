import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga'



ReactGA.initialize("AW-16454133494");
ReactGA.initialize("UA-189148949-1");
ReactGA.initialize("G-46QBBRE13F");
ReactGA.initialize("GTM-NLQC3VXP");
ReactGA.initialize("GTM-KD8WV5FM");



ReactGA.send({
  hitType:"pageview",
  page:window.location.pathname
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <ToastContainer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
